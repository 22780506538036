import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Text-align: justify pour une nouvelle technique de grilles",
  "description": "Une nouvelle technique CSS pour facilement créer des grilles.",
  "date": "2013-06-03T00:00:00.000Z",
  "path": "/articles/text-align-justify-pour-une-nouvelle-technique-de-grilles/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Je suis tombé sur une nouvelle technique publiée par l’agence new-yorkaise `}<a parentName="p" {...{
        "href": "http://www.barrelny.com/"
      }}>{`Barrel`}</a>{` pour créer des grilles qui évitent ENFIN les prises de têtes que sont les marges horizontales. Je la partage aujourd’hui en français et surtout je la conseille vivement comme méthode de remplacement des techniques traditionnelles.`}</p>
    <h2>{`Les techniques habituelles`}</h2>
    <p>{`En fait quand je crée des grilles, par exemple de listings produits (le plus souvent responsive), j’utilise une des deux techniques suivantes :`}</p>
    <ol>
      <li parentName="ol">{`Je fais flotter tous mes blocs à gauche à coups de `}<code parentName="li" {...{
          "className": "text"
        }}>{`float:left`}</code>{` puis j’applique une méthode de `}<a parentName="li" {...{
          "href": "http://nicolasgallagher.com/micro-clearfix-hack/"
        }}>{`clearfix`}</a>{` au bloc conteneur pour éviter les effets annexes liés à l’utilisation de mes `}<code parentName="li" {...{
          "className": "text"
        }}>{`floats`}</code>{`.`}</li>
      <li parentName="ol">{`Ou j’utilise le pseudo-sélecteur `}<code parentName="li" {...{
          "className": "text"
        }}>{`nth-child`}</code>{` pour cibler chaque premier élément de ma ligne de produits et supprimer sa marge gauche.`}</li>
    </ol>
    <p>{`Le problème de la première méthode, c’est qu’elle oblige à recourir à du clearfix, dont je ne suis pas forcément fan. Elle rajoute aussi une marge (invisible) à droite, qui risque donc de créer du scolling horizontal sur des petites résolutions.`}</p>
    <p>{`Le problème de la deuxième méthode, c’est qu’elle est plutôt pénible à gérer de manière responsive, car il faut recalculer tous les `}<code parentName="p" {...{
        "className": "text"
      }}>{`nth-child`}</code>{` à chaque changement de nombre d’items affichés par ligne. Sans compter que `}<code parentName="p" {...{
        "className": "text"
      }}>{`nth-child`}</code>{` n’est pas compris par IE8-.`}</p>
    <h2>{`Text-align: justify`}</h2>
    <p>{`La technique proposée par Barrel est la suivante : appliquer la propriété `}<code parentName="p" {...{
        "className": "text"
      }}>{`text-align:justify`}</code>{` (fonctionnant avec `}<code parentName="p" {...{
        "className": "text"
      }}>{`inline`}</code>{` ET `}<code parentName="p" {...{
        "className": "text"
      }}>{`inline-block`}</code>{`) au conteneur pour laisser le navigateur gérer tout seul les marges horizontales entre chacun des éléments d’une même ligne. Par contre, le comportement par défaut de `}<code parentName="p" {...{
        "className": "text"
      }}>{`text-align:justify`}</code>{` opère sur toutes les lignes d’un texte sauf la dernière. Si il y a une seule ligne, celle-ci sera aussi considérée comme la dernière. Pour que la magie opère il faut donc créer une "ligne" invisible située en-dessous de la ligne concernée (visible en bleu ci-dessous) :`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "666px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a9f8e7f7295291fc7d1d79c3b517fd46/09a0d/justify-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.49514563106796%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHWyJYF/8QAFhABAQEAAAAAAAAAAAAAAAAAACEB/9oACAEBAAEFAqrH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxAAAwEAAAAAAAAAAAAAAAAAABGBIf/aAAgBAQABPyGih1p//9oADAMBAAIAAwAAABCD/wD/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QpX//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAITHRQVGR/9oACAEBAAE/EGpr2NxvjHsblmzfJn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Justify 1",
            "title": "Justify 1",
            "src": "/static/a9f8e7f7295291fc7d1d79c3b517fd46/09a0d/justify-1.jpg",
            "srcSet": ["/static/a9f8e7f7295291fc7d1d79c3b517fd46/7c93a/justify-1.jpg 206w", "/static/a9f8e7f7295291fc7d1d79c3b517fd46/fff8b/justify-1.jpg 412w", "/static/a9f8e7f7295291fc7d1d79c3b517fd46/09a0d/justify-1.jpg 666w"],
            "sizes": "(max-width: 666px) 100vw, 666px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Pour reprendre l’exemple de Barrel il faudrait donc avoir le code suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`grid`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`break`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-align`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` justify`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* supprimer l’espace entre les différents éléments */`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid li`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 23%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid .break`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Ou, pour une sémantique plus propre, se passer de l’élément `}<code parentName="p" {...{
        "className": "text"
      }}>{`.break`}</code>{` et ajouter un `}<code parentName="p" {...{
        "className": "text"
      }}>{`:after`}</code>{` au conteneur :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid:after`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Un autre souci à contourner, c’est le nombre d’éléments par ligne. Mettons qu’on ait 4 éléments par ligne et que la dernière ligne n’en affiche que 2. Les 2 éléments vont se justifier sur la longueur de la ligne, donnant un résultat plutôt indésirable :`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "666px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1a11ca92d6cf25ec9dee14d1a5ad2b14/09a0d/justify-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.79611650485437%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBv/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeusJoD/AP/EABcQAQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQEAAQUCcTY//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAACAwEBAAAAAAAAAAAAAAAAARExQSGB/9oACAEBAAE/IettSSWlTT0qf//aAAwDAQACAAMAAAAQw8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/EB//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhUYGR/9oACAEBAAE/EAGOIrch2Jdt7HGim9lrivsFe5//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Justify 2",
            "title": "Justify 2",
            "src": "/static/1a11ca92d6cf25ec9dee14d1a5ad2b14/09a0d/justify-2.jpg",
            "srcSet": ["/static/1a11ca92d6cf25ec9dee14d1a5ad2b14/7c93a/justify-2.jpg 206w", "/static/1a11ca92d6cf25ec9dee14d1a5ad2b14/fff8b/justify-2.jpg 412w", "/static/1a11ca92d6cf25ec9dee14d1a5ad2b14/09a0d/justify-2.jpg 666w"],
            "sizes": "(max-width: 666px) 100vw, 666px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Pour éviter ce problème il faudra rajouter des "placeholders" invisibles qui permettront de rétablir l’équilibre. Dans cet exemple il en faudra donc 2 pour obtenir le résultat suivant :`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "666px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e10f1211202a7f004ecd66bf9c7bf5aa/09a0d/justify-3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.79611650485437%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQADBv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHrkE0iL//EABYQAQEBAAAAAAAAAAAAAAAAAAEQAP/aAAgBAQABBQKFN//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhABAQEBAAMAAAAAAAAAAAAAAQAhETFxkf/aAAgBAQABPyFddgXewQ2Tbnv7eN//2gAMAwEAAgADAAAAEBPf/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQMBAT8QI//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QH//EABsQAAIDAAMAAAAAAAAAAAAAAAERADFRIUGR/9oACAEBAAE/ECWbgYBxI2IBMuAa72JvpASs32SZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Justify 3",
            "title": "Justify 3",
            "src": "/static/e10f1211202a7f004ecd66bf9c7bf5aa/09a0d/justify-3.jpg",
            "srcSet": ["/static/e10f1211202a7f004ecd66bf9c7bf5aa/7c93a/justify-3.jpg 206w", "/static/e10f1211202a7f004ecd66bf9c7bf5aa/fff8b/justify-3.jpg 412w", "/static/e10f1211202a7f004ecd66bf9c7bf5aa/09a0d/justify-3.jpg 666w"],
            "sizes": "(max-width: 666px) 100vw, 666px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Le code ressemblera donc à :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`grid`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`placeholder`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`placeholder`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`La formule à appliquer pour connaitre le nombre de placeholders à utiliser, et ce quelque soit le nombre d’items présents sur la dernière ligne, est la suivante :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`nombre de placeholders = nombre maximum d’éléments par ligne - 2`}</code></pre></div>
    <p>{`Pourquoi 2 ? Parce que si un seul produit s’affiche sur la dernière ligne, il s’affichera de toute façon tout à gauche.`}</p>
    <h2>{`Justify & Responsive design`}</h2>
    <p>{`L’énorme avantage de cette technique, c’est qu’elle est très facilement adaptable au responsive design, par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid li`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 5%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 400px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid li`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 48%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 4%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 600px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid li`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 31%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 3%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 800px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.grid li`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 23%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2.5%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Beaucoup plus simple et moins casse-tête que les `}<code parentName="p" {...{
        "className": "text"
      }}>{`nth-child`}</code>{` :) Par contre les marges horizontales sont calculées automatiquement, il faut donc un peu tâtonner pour trouver les valeurs exactes à appliquer aux `}<code parentName="p" {...{
        "className": "text"
      }}>{`width`}</code>{` de nos éléments…`}</p>
    <h2>{`Le support`}</h2>
    <p>{`Voilà le gros avantage de cette méthode : en plus d’être simple à intégrer, elle est compatible à partir de IE7 (même si pour celui-ci, il faudra recourir à quelques astuces). Je l’ai déjà implémentée avec succès sur un projet en cours, et j’y aurai sans doute de plus en plus recours avec le temps.`}</p>
    <p className="info">
  Cet article a été adapté de{' '}
  <a href="http://www.barrelny.com/blog/text-align-justify-and-rwd/">
    l’article
  </a>{' '}
  originalement publié par <a href="http://www.barrelny.com/">Barrel</a>. Un
  exemple est disponible sur{' '}
  <a href="http://codepen.io/patrickkunka/pen/GECBF">Codepen</a>.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      